import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { AnchorLink } from 'gatsby-plugin-anchor-links';
import { Title, Button, Section, Box } from '../../components/Core';

const Hero = () => {
  return (
    <>
      {/* <!-- Hero Area --> */}
      <Section bg="dark">
        <div className="pt-5"></div>
        <Container
          className="position-relative"
          css={`
            z-index: 10;
          `}
        >
          <Row>
            <Col md="11" lg="10" xl="9">
              <Box py={4}>
                <Title
                  my={4}
                  variant="hero"
                  color="light"
                  data-aos="fade-up"
                  data-aos-duration="1500"
                  data-aos-once="true"
                  data-aos-delay="1000"
                >
                  Tecnologia <br />a favor do seu negócio
                </Title>
                <Box
                  pt="12px"
                  data-aos="fade-up"
                  data-aos-duration="1500"
                  data-aos-once="true"
                  data-aos-delay="1300"
                >
                  <Button
                    onClick={() => {
                      window.open(
                        'https://instagram.com/lab032.tech',
                        '_blank'
                      );
                    }}
                  >
                    Site em construção.
                    <br />
                    Fale conosco via Instagram!
                    <br />
                  </Button>
                </Box>
              </Box>
            </Col>
          </Row>
        </Container>
      </Section>
    </>
  );
};

export default Hero;
