import React from 'react';
import Hero from '../sections/indexPage/Hero';
import Fact from '../sections/indexPage/Fact';
import Content from '../sections/indexPage/Content';
import CaseStudies from '../sections/indexPage/CaseStudies';
import Reviews from '../sections/indexPage/Reviews';
import Contact from '../sections/indexPage/Contact';

import PageWrapper from '../components/PageWrapper';

const IndexPage = () => {
  return (
    <>
      <PageWrapper headerDark footerDark>
        <Hero />
        {/* <Fact /> */}
        {/* <Content />
        <CaseStudies />
        <Reviews />
        <Contact /> */}
      </PageWrapper>
    </>
  );
};
export default IndexPage;
